<template>
  <n-card no-margin class="w-full relative flex items-start ">
    <n-icon :name="claim.contract.category" class="mr-6" xxl></n-icon>
    <div class="w-full">
      <div class="grid 2xl:flex">
        <div class="pr-2">
          <h4 class="typography-h4 margin-bottom-none text-sm sm:text-base">
            {{ claim.contract.description }}
          </h4>
          <span class="text-xs font-serif">
            {{ claim.contract.contract_description }}
          </span>
        </div>
        <div class="w-full">
          <div
            :class="[
              'mt-2 2xl:mt-0 w-min whitespace-nowrap text-xs 2xl:mr-0 mr-auto 2xl:ml-auto bg-brand-purple-100 h-9.5 leading-9 px-4 rounded-full font-semibold text-brand-purple-500 '
            ]"
          >
            <n-html
              :text="
                $t(
                  `claims.status.${claim.status
                    .replace(' ', '_')
                    .toLowerCase()}`
                )
              "
            />
          </div>
        </div>
      </div>
      <div class="w-full border-t border-gray-300 mr-10 mt-6">
        <n-button-link
          class="mt-4"
          bold
          @click.native="$router.push({ name: 'claims.index' }).catch(() => {})"
        >
          <div class="flex flex-row">
            <n-html :text="$t('claims.more_info')" />
            <n-icon name="Chevron-right" small></n-icon>
          </div>
        </n-button-link>
      </div>
    </div>
  </n-card>
</template>

<script>
export default {
  name: "components.claims.card-claim-overview",
  props: {
    claim: Object,
    dense: {
      type: Boolean,
      default: false
    },
    selected: Boolean
  }
};
</script>

<style>
.claim-card .card-body {
  padding-bottom: 10px;
}

.claim-card-small-active {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
</style>
