<template>
  <div>
    {{/* Dashboard head including image with decoration */}}
    <div class="col-span-12 w-full grid grid-cols-12 overflow-x-hidden">
      {{/* Text left side */}}
      <div
        class="bg-brand-light-purple-100 col-span-12 xl:col-span-6 flex z-10"
      >
        <div class="w-full mx-auto px-5 sm:px-8 sm:px-12 md:px-16 py-12">
          <h1
            class="font-serif uppercase text-brand-purple-500 text-3xl font-extrabold"
          >
            <n-html :text="$t('overview.welcome')" />, <n-html :text="title" />
            <n-html :text="salutation" />
          </h1>
          <h2
            class="mt-10 mb-5 font-serif text-2xl font-semibold text-gray-900"
          >
            <n-html :text="$t('overview.title')" />
          </h2>
          <p
            class="font-serif leading-7 mb-7"
            v-html="$t('overview.subtitle')"
          />
          <n-button-regular
            class="mb-10"
            @click.native="
              $router.push({ name: 'insurances.new' }).catch(() => {})
            "
            ><n-html :text="$t('overview.new_issurance_button')"
          /></n-button-regular>
        </div>
      </div>
      {{/**/}}
      {{/* skewed decoration for dashboard in front of image right side */}}
      <div
        class="hidden xl:grid grid-cols-12 col-span-6 bg-dashboard-greeting bg-cover bg-center"
      >
        <div
          class="col-span-1 bg-brand-light-purple-100 p-4 origin-top transform skew-x-12 -ml-96"
        />
        <div class="col-span-1 flex origin-top transform skew-x-12">
          <div class="bg-brand-light-purple-transparent-100 p-5 ml-8" />
          <div class="bg-brand-light-purple-transparent-100 p-2.5 ml-8" />
        </div>
      </div>
      {{/**/}}
    </div>
    {{/**/}}
    {{/* First section of ongoing claims */}}
    <div
      class="mx-auto px-5 sm:px-8 sm:px-12 md:px-16 pt-8 pb-8"
      v-if="activeClaims.length > 0"
    >
      <h1 class="text-2xl font-semibold text-gray-900 text-left w-full pb-6">
        <n-html :text="$t('claims.title')" />
      </h1>
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <CardClaimDetails
          v-for="claim in activeClaims"
          v-bind:key="claim.id"
          :claim="claim"
        />
      </div>
    </div>
    {{/**/}}
    {{/* Second section of ongoing products */}}
    <div class="mx-auto px-5 sm:px-8 sm:px-12 md:px-16 gap-4 pt-8 mb-6">
      <h1 class="text-2xl font-semibold text-gray-900 text-left w-full pb-6">
        <n-html :text="$t('products.title')" />
      </h1>
      <div class="grid grid-cols-12 gap-4">
        <CardProductSmall
          v-for="contract in contracts"
          v-bind:key="contract.id"
          :contract="contract"
        />
      </div>
    </div>
    {{/**/}}
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CardProductSmall from "./../components/products/card-product-small";
import CardClaimDetails from "./../components/claims/card-claim-overview.vue";

export default {
  name: "pages.index",
  components: {
    CardProductSmall,
    CardClaimDetails
  },
  data: () => ({
    new_insurance_link: "https://www.harmony.nl/verzekeringafsluiten/"
  }),
  computed: {
    ...mapGetters({
      customer: "customer/get",
      title: "customer/getTitle",
      salutation: "customer/getSalutation",
      contracts: "contracts/getAllActive",
      claims: "claims/getAll"
    }),
    activeClaims() {
      const active = ["open", "on_hold", "accepted"];
      return this.claims.filter(c =>
        active.includes(c.status.replace(" ", "_").toLowerCase())
      );
    }
  },
  methods: {
    openNewInsuranceLink() {
      this.$router.push("/insurances/new");
    }
  }
};
</script>

<style>
.homepage {
  height: 100%;
}

.new-insurance-card .card-body {
  padding: 0;
}

.new-insurance-card-text {
  padding: 20px 20px 20px 30px;
}

.new-insurance-card-image {
  height: 100%;
  width: 100%;
}
</style>
