<template>
  <n-card
    class="relative p-2 col-span-12 lg:col-span-6 grid grid-cols-6 gap-x-4 w-full"
    :class="
      `${
        selected
          ? 'bg-brand-purple-100 bg-opacity-30 border border-brand-purple-500'
          : ''
      }`
    "
    no-margin
    :use-border="useBorder"
  >
    <n-icon
      v-if="selected"
      class="absolute right-2 bottom-2"
      name="Check"
      small
    ></n-icon>
    <div class="col-span-1 flex justify-center ">
      <n-icon :name="contract.category" class="flex" xxl></n-icon>
    </div>
    <div class="flex flex-col justify-evenly col-span-5">
      <h4 class="text-xs sm:text-base mb-2" v-if="!contract.multidevice">
        {{ contract.description }}
      </h4>
      <span class="text-xs font-serif">
        {{ contract.contract_description }}
      </span>
      <span class="text-xs" v-if="contract.multidevice">
        {{ contract.navision_ext_id }}
      </span>
    </div>
    <div
      class="col-span-5 col-start-2 row-span-2 row-start-3 border-t border-gray-300 mr-10 mt-6"
      v-if="!dense"
    >
      <n-button-link
        class="mt-4"
        bold
        @click.native="
          $router.push({ name: 'insurances.index' }).catch(() => {})
        "
      >
        <div class="flex flex-row">
          <n-html :text="$t('products.more_info')" />
          <n-icon name="Chevron-right" small></n-icon>
        </div>
      </n-button-link>
    </div>
  </n-card>
</template>

<script>
export default {
  name: "components.products.card-product-small",
  props: {
    contract: Object,
    dense: {
      type: Boolean,
      default: false
    },
    useBorder: {
      type: Boolean,
      default: false
    },
    selected: Boolean
  }
};
</script>

<style>
.product-card .card-body {
  padding-bottom: 10px;
}

.product-card-small-active {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
</style>
